import React from "react";

import { StyleSheet, css } from "aphrodite";
import { Color } from "../../Global/Color";
import { GUTTER, BORDER_RADIUS } from "../../Global/Size";

import { PRIMARY_TEXT } from "../../Global/Font";

export default props => (
  <div className={css(styles.cookieBanner)}>
    By using this site, you consent to our use of cookies to deliver a great
    experience for you and analyse our traffic
    <button onClick={() => props.onClick()} className={css(styles.button)}>
      OK
    </button>
  </div>
);

const styles = StyleSheet.create({
  cookieBanner: {
    position: "fixed",
    bottom: "0",
    width: "100%",
    textAlign: "center",
    backgroundColor: Color.PRIMARY_BLUE,
    padding: `${GUTTER * 2}px`,
    color: Color.WHITE,
    fontFamily: PRIMARY_TEXT,
    zIndex: 10000,
    lineHeight: "2em"
  },
  button: {
    margin: `0 ${GUTTER}px`,
    padding: `${GUTTER}px ${GUTTER * 2}px`,
    backgroundColor: Color.PRIMARY_BLUE,
    border: `1px solid ${Color.WHITE}`,
    borderRadius: `${BORDER_RADIUS}px`,
    color: Color.WHITE,
    cursor: "pointer"
  }
});
