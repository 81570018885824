import Axios from "axios";
import {
  MAKING_PAYMENT,
  MAKING_PAYMENT_SUCCESS,
  MAKING_PAYMENT_FAILURE
} from "../actions/types";

const CancelToken = Axios.CancelToken;
const source = CancelToken.source();

export const makePayment = ({ auth, price, token, coins }) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: MAKING_PAYMENT
  });
  const { baseURI } = getState().appConfig;
  const body = {
    amount: price * 100,
    token
  };
  const headers = { Authorization: auth };

  try {
    const { status } = await Axios.post(
      `${baseURI}/payment`,
      body,
      { headers },
      {
        cancelToken: source.token,
        timeout: 10000
      }
    );
    dispatch({
      type: MAKING_PAYMENT_SUCCESS
    });
  } catch (thrown) {
    console.log(thrown);
    if (!Axios.isCancel(thrown)) {
      dispatch({
        type: MAKING_PAYMENT_FAILURE
      });
    }
  }
};
