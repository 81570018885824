import React, { PureComponent } from "react";
import { StyleSheet, css } from "aphrodite";

import { PRIMARY_TEXT } from "../../Global/Font";
import { BORDER_RADIUS, GUTTER } from "../../Global/Size";
import { Color } from "../../Global/Color";
import { config } from "../../config";

export default class Player extends PureComponent {
  state = {
    showShirt: this.props.showOnMount || false,
    input: this.props.showOnMount || "",
    locked: false
  };

  static defaultProps = {
    showClues: false
  };

  handleChange = event => {
    if (this.state.locked) return;
    const { changeCorrect } = this.props;
    const value = event.target.value.slice(0, this.props.name.length);
    const showShirt = value.toLowerCase() === this.props.name.toLowerCase();

    if (changeCorrect) {
      if (this.state.showShirt !== showShirt) {
        this.setState({ locked: true });
        changeCorrect(showShirt);
      }
    }

    this.setState({
      input: value,
      showShirt
    });
  };

  createNameInput = name => {
    return "*".repeat(name.length);
  };
  render() {
    const {
      name,
      x,
      y,
      shirt,
      num,
      nation,
      showClues,
      showOnMount
    } = this.props;
    const { showShirt, input } = this.state;
    const divPosition = {
      top: `${y}px`,
      left: `${x}px`
    };

    return (
      <div key={`${x}-${y}`} className={css(styles.holder)} style={divPosition}>
        <div className={css(styles.container)}>
          {showClues && nation && nation.badge && (
            <img
              className={css(styles.badge)}
              src={`${config.prod.imgUrl}${nation.badge}`}
              width="30px"
              height="30px"
              alt={nation.name}
            />
          )}

          <img
            className={css(styles.edge)}
            src="/images/shirts/shirt_edge.png"
            width="32px"
            height="32px"
            alt="TFQ Shirt Edge"
          />
          <span className={css(styles.shirtNumber)}>{num}</span>
          {shirt && (
            <img
              className={css([
                styles.shirtOverlay,
                showShirt && styles.showShirt
              ])}
              src={`${config.prod.imgUrl}${shirt}`}
              width="32px"
              height="32px"
              alt="TFQ Shirt"
            />
          )}
          <input
            onChange={this.handleChange}
            size={name.length + 2}
            maxLength={name.length}
            className={css(styles.nameContainer)}
            placeholder={this.createNameInput(name)}
            value={showOnMount ? name : input}
          />
          <span className={css(styles.nameLength)}>{name.length}</span>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  holder: {
    position: "absolute",
    fontFamily: PRIMARY_TEXT,
    color: Color.WHITE,
    width: "150px",
    textAlign: "center",
    cursor: "pointer"
  },
  container: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  nameContainer: {
    display: "inline-block",
    backgroundColor: "rgba(0,0,0,0.6)",
    borderRadius: `${BORDER_RADIUS}px`,
    fontSize: "12px",
    position: "absolute",
    bottom: "-5px",
    padding: `${GUTTER / 2}px`,
    textTransform: "capitalize",
    color: "white",
    border: "none",
    textAlign: "center",
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  shirtNumber: {
    position: "absolute",
    bottom: "19px",
    width: "11px",
    height: "11px",
    textAlign: "center",
    // left: "50px",
    top: "10px",
    backgroundColor: "rgba(255,255,255,0.5)",
    color: "black",
    borderRadius: `${BORDER_RADIUS}px`,
    fontSize: "10px"
  },
  nameLength: {
    position: "relative",
    bottom: "15px",
    left: "20px",
    backgroundColor: "red",
    color: "white",
    width: "16px",
    height: "16px",
    lineHeight: "16px",
    padding: "1px",
    borderRadius: `${BORDER_RADIUS}px`,
    textAlign: "center",
    fontSize: "12px"
  },
  shirtOverlay: {
    position: "absolute",
    top: "1px",
    opacity: 0,
    transition: "opacity .25s ease-in-out"
  },
  showShirt: {
    opacity: 1
  },
  badge: {
    position: "absolute",
    left: "40px"
  }
});
