import { PRIMARY_TEXT } from "./Font";
import { BORDER_RADIUS } from "./Size";
import { Color } from "./Color";

export const formStyles = {
  holder: {
    margin: "20px"
  },
  body: {
    maxWidth: "400px",
    minWidth: "200px",
    margin: "auto",
    position: "relative",
    background: "rgba(0,0,0,0.1)",
    padding: "25px",
    borderRadius: `${BORDER_RADIUS}px`,
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
    fontFamily: PRIMARY_TEXT
  },
  input: {
    "-webkit-appearance": "none",
    font: "16px 'Roboto', Helvetica, Arial, sans-serif",
    border: "1px solid #ccc",
    background: "#FFF",
    padding: "10px",
    width: "100%",
    boxSizing: "border-box"
  },
  fieldSet: {
    border: "medium none",
    margin: "0 0 10px",
    minWidth: "100%",
    padding: "0",
    width: "100%"
  },
  label: {
    color: "#FFF",
    fontSize: "12px",
    lineHeight: "28px",
    display: "block",
    textTransform: "capitalize"
  },
  button: {
    cursor: "pointer",
    width: "100%",
    border: "none",
    background: Color.SECONDARY_GREEN,
    color: "#FFF",
    margin: "0 0 5px",
    fontSize: "15px",
    minHeight: "40px",
    borderRadius: `${BORDER_RADIUS}px`,
    ":hover": {
      background: Color.SECONDARY_GREEN,
      transition: "background-color 0.3s ease-in-out"
    },
    ":active": {
      boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.5)"
    }
  }
};
