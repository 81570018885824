import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import LocalStorageManager from "./middlewares/localStorageManager";

import { config } from "./config";

import reducers from "./reducers";
import App from "./components/App";

const appConfig =
  process.env.REACT_APP_SERVER_URI || process.env.NODE_ENV === "production"
    ? config.prod
    : config.dev;

const middleware = [reduxThunk, LocalStorageManager];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(
  reducers,
  {
    appConfig,
    ffs: localStorage.getItem("ffs")
      ? JSON.parse(localStorage.getItem("ffs"))
      : null,
    auth: {
      authenticated: localStorage.getItem("token"),
      user: JSON.parse(localStorage.getItem("user"))
      // userId: localStorage.getItem("userId"),
      // completed: JSON.parse(localStorage.getItem("completed"))
    },
    cookieManager: {
      acceptedCookies: !!localStorage.getItem("acceptedCookies")
    },

    onboarding: {
      acceptedOnboarding: localStorage.getItem("onboarded")
        ? JSON.parse(localStorage.getItem("onboarded"))
        : []
    }
  },
  enhancer
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);
