import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";

import { Search, CoinsDisplay } from "../PageElements";

import { Color } from "../../Global/Color";
import { GUTTER, MEDIA_MEDIUM } from "../../Global/Size";
import { PRIMARY_TEXT } from "../../Global/Font";

class Header extends Component {
  render() {
    const { user, ffs, showShopper } = this.props;
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.containerMaximus)}>
          <Link to="/" className={css(styles.flexChild, styles.logo)}>
            <img
              src="/images/logos/tfq_logo.svg"
              width="40px"
              alt="That Football Quiz logo"
            />
          </Link>
          <Search />
          {user && ffs && ffs.coins && (
            <div className={css(styles.flexChild)}>
              <CoinsDisplay coins={user.coins} showShopper={showShopper} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
    ffs: state.ffs
  };
}

export default connect(mapStateToProps)(Header);

const styles = StyleSheet.create({
  container: {
    backgroundImage: `linear-gradient(to bottom,  ${Color.PRIMARY_GREEN}, ${Color.SECONDARY_GREEN})`
  },
  containerMaximus: {
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "40px",
    height: "60px",
    maxWidth: "1024px",
    margin: "auto",
    padding: `0 ${GUTTER}`,
    [`@media ${MEDIA_MEDIUM}`]: {
      padding: `0 ${GUTTER * 2}`
    }
  },
  flexChild: {
    alignSelf: "center"
  },
  logo: {
    paddingTop: `${GUTTER * 2}px`,
    margin: `${GUTTER * 2}px`
  },
  username: {
    marginRight: "12px",
    color: Color.WHITE,
    fontFamily: PRIMARY_TEXT
  }
});
