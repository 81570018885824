import { GROWLER_HIDE, GROWLER_HIDDEN, GROWLER_SHOW } from "../actions/types";

const INITIAL_STATE = {
  type: null,
  message: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GROWLER_HIDE:
      return INITIAL_STATE;
    case GROWLER_HIDDEN:
      return {
        ...state
      };
    case GROWLER_SHOW:
      return action.payload;
    default:
      return state;
  }
}
