import React from "react";
import { StyleSheet, css } from "aphrodite";

import { formStyles } from "../../Global/Form";
export default ({ children }) => (
  <button className={css(styles.button)}>{children}</button>
);

const styles = StyleSheet.create({
  button: formStyles.button
});
