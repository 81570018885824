import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";

import { Helmet } from "react-helmet";

import { Header, Nav, Footer } from "./Layouts";
import { Color } from "../Global/Color";
import { GUTTER, MEDIA_MEDIUM } from "../Global/Size";

import { Loading, Onboarding, Shopper } from "./PageElements";
import { acceptCookies, acceptOnboarding } from "../actions";

import { AdminRoute } from "./PageElements/Auth/AdminRoute";
import { ScreenMessage, Growler, CookieBanner } from "./PageElements";
import GA from "../utils/GoogleAnalytics";

const HomePage = lazy(() => import("./Pages/HomePage"));
const MatchPage = lazy(() => import("./Pages/MatchPage"));
const CreateMatchPage = lazy(() => import("./Pages/CreateMatchPage"));
const ViewYourMatchesPage = lazy(() => import("./Pages/ViewYourMatchesPage"));
const AdminPage = lazy(() => import("./Pages/AdminPage"));
const AdminEditMatch = lazy(() => import("./Pages/AdminEditMatch"));

const SignUp = lazy(() => import("./PageElements/Auth/SignUp"));
const SignOut = lazy(() => import("./PageElements/Auth/SignOut"));
const SignIn = lazy(() => import("./PageElements/Auth/SignIn"));

const Feedback = lazy(() => import("./PageElements/Feedback"));
const HowToPlay = lazy(() => import("./PageElements/HowToPlay"));
const FeatureSwitches = lazy(() => import("./PageElements/FeatureSwitches"));

const ResendVerificationEmail = lazy(() =>
  import("./PageElements/Auth/ResendVerificationEmail")
);
const ForgottenPassword = lazy(() =>
  import("./PageElements/Auth/ForgottenPassword")
);
const VerifyEmail = lazy(() => import("./PageElements/Auth/VerifyEmail"));
const ResetPassword = lazy(() => import("./PageElements/Auth/ResetPassword"));

class App extends Component {
  state = {
    showShopper: false
  };
  handleAcceptCookie = () => {
    const { acceptCookies } = this.props;
    acceptCookies(true);
  };
  handleOnboardingClick = type => () => {
    const { acceptOnboarding } = this.props;
    acceptOnboarding(type);
  };
  handleShowShopper = show => {
    console.log(show);
    this.setState({ showShopper: show });
  };

  render() {
    const { growl, acceptedCookies, acceptedOnboarding } = this.props;
    const { showShopper } = this.state;
    const acceptedLoginOnboarding = acceptedOnboarding.indexOf("login") > -1;

    return (
      <div className={css(styles.container)}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TFQ: Home</title>
          <link rel="canonical" href="https://thatfootballquiz.com" />
        </Helmet>
        {showShopper && <Shopper showShopper={this.handleShowShopper} />}
        {growl.type && <Growler {...growl} />}
        <Header showShopper={this.handleShowShopper} />
        <Nav />

        <div className={css([styles.appBody, styles.gridRestrictor])}>
          {!acceptedLoginOnboarding && (
            <Onboarding
              extraStyles={{ right: "10px" }}
              buttonText={"Got it!"}
              onClick={this.handleOnboardingClick("login")}
            >
              Sign in to save your completed matches and to create your own.
            </Onboarding>
          )}
          <Suspense fallback={<Loading />}>
            {GA.init({ acceptedCookies }) && <GA.RouteTracker />}
            <Switch>
              <Route
                path="/"
                exact
                render={routeProps => <HomePage {...routeProps} />}
              />

              <Route
                path="/match/:id"
                render={routeProps => <MatchPage {...routeProps} />}
              />
              <Route
                path="/createMatch"
                render={routeProps => <CreateMatchPage {...routeProps} />}
              />
              <Route
                path="/editMatch/:id"
                render={routeProps => <CreateMatchPage {...routeProps} />}
              />
              <Route
                path="/userMatches"
                exact
                render={routeProps => <ViewYourMatchesPage {...routeProps} />}
              />
              <Route
                path="/screenMessage"
                exact
                render={({
                  location: {
                    state: { message }
                  }
                }) => {
                  return <ScreenMessage message={message} />;
                }}
              />
              <Route
                path="/verifyEmail/:token"
                exact
                render={routeProps => {
                  return <VerifyEmail {...routeProps} />;
                }}
              />
              <Route path="/signup" component={SignUp} />
              <Route
                path="/resendVerificationEmail"
                component={ResendVerificationEmail}
              />
              <Route path="/signout" component={SignOut} />
              <Route path="/signin" component={SignIn} />
              <Route path="/feedback" component={Feedback} />
              <Route path="/forgottenPassword" component={ForgottenPassword} />
              <Route path="/resetPassword/:token" component={ResetPassword} />
              <Route path="/howtoplay" component={HowToPlay} />

              <AdminRoute
                path="/admin"
                user={this.props.user}
                component={AdminPage}
              />
              <AdminRoute
                path="/adminEditMatch/:id"
                user={this.props.user}
                component={AdminEditMatch}
              />
              <AdminRoute
                path="/featureswitches"
                user={this.props.user}
                component={FeatureSwitches}
              />
            </Switch>
          </Suspense>
        </div>
        {!acceptedCookies && <CookieBanner onClick={this.handleAcceptCookie} />}
        <Footer />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    background: Color.PRIMARY_GREEN
  },
  gridRestrictor: {
    maxWidth: "1024px",
    margin: `auto`
  },
  appBody: {
    position: "relative",
    minHeight: "calc(100vh - 150px)",
    padding: `${GUTTER}px 0`,
    [`@media ${MEDIA_MEDIUM}`]: {
      padding: `${GUTTER}px`
    }
  }
});

const mapStateToProps = state => {
  return {
    growl: state.growl,
    auth: state.auth.authenticated,
    user: state.auth.user,
    acceptedCookies: state.cookieManager.acceptedCookies,
    acceptedOnboarding: state.onboarding.acceptedOnboarding
  };
};

export default connect(
  mapStateToProps,
  { acceptCookies, acceptOnboarding }
)(App);
