import {
  MAKING_PAYMENT,
  MAKING_PAYMENT_FAILURE,
  MAKING_PAYMENT_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
  makingPayment: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MAKING_PAYMENT:
      return { makingPayment: true };
    case MAKING_PAYMENT_SUCCESS:
      return { makingPayment: false };
    case MAKING_PAYMENT_FAILURE:
      return { makingPayment: false };
    default:
      return state;
  }
}
