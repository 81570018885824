import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { StripeButton } from "../PageElements";
import { GUTTER, BORDER_RADIUS } from "../../Global/Size";
import { PRIMARY_TEXT } from "../../Global/Font";
import { Color } from "../../Global/Color";

class Shopper extends Component {
  state = {};
  render() {
    const { showShopper, makingPayment } = this.props;
    return (
      <div className={css(styles.overlay)}>
        <div className={css(styles.flexed)}>
          <div className={css(styles.holder)}>
            {makingPayment ? (
              <span>Making Payment</span>
            ) : (
              <>
                <button
                  className={css(styles.close)}
                  onClick={() => showShopper(false)}
                >
                  x
                </button>
                <h1 className={css(styles.title)}>Buy tokens</h1>
                <StripeButton price={1} text={"5 tokens for £1"} coins={5} />
                <StripeButton price={2} text={"15 tokens for £2"} coins={15} />
                <p className={css(styles.info)}>
                  Reveal national flags = 2 tokens
                </p>
                <p className={css(styles.info)}>Reveal all names = 5 tokens</p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.authenticated,
    user: state.auth.user,
    makingPayment: state.payment.makingPayment
  };
};

export default connect(
  mapStateToProps,
  null
)(Shopper);

const iKeyFrames = {
  in: {
    from: { opacity: "0" },
    to: { opacity: "1" }
  }
};

const styles = StyleSheet.create({
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: "100",
    opacity: "0",
    animationName: iKeyFrames.in,
    animationDuration: "1s",
    animationFillMode: "forwards"
  },
  flexed: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: Color.WHITE,
    height: "100vh"
  },
  holder: {
    fontFamily: PRIMARY_TEXT,
    backgroundColor: Color.PRIMARY_GREEN,
    padding: `${GUTTER * 2}px`,
    maxWidth: "310px",
    borderRadius: `${BORDER_RADIUS}px`,
    position: "relative"
  },
  title: {
    margin: "0 0 16px 0",
    padding: "0"
  },
  info: {
    fontFamily: "Arial, sans-serif",
    lineHeight: "1em",
    padding: "0"
  },
  close: {
    backgroundColor: Color.WHITE,
    color: Color.BLACK,
    fontSize: "20px",
    position: "absolute",
    right: "-15px",
    top: "-15px",
    padding: "5px 10px",
    fontFamily: PRIMARY_TEXT,
    borderRadius: "100px",
    border: "3px solid black",
    lineHeight: "1em"
  }
});
