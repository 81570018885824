import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { withRouter } from "react-router-dom";

import { GUTTER, BORDER_RADIUS, MEDIA_LARGE } from "../../Global/Size";
import { PRIMARY_TEXT } from "../../Global/Font";
import { Color } from "../../Global/Color";

import { fetchMatches, changeSearchText } from "../../actions";

class Search extends Component {
  state = {
    openFilters: false
  };

  searchAPI = () => {
    this.props.fetchMatches({
      searchText: this.props.searchText,
      offset: this.props.offset
    });
  };

  searchAPIDebounced = AwesomeDebouncePromise(this.searchAPI, 300);

  handleChange = async event => {
    const searchText = event.target.value;
    this.props.changeSearchText(searchText);
    this.searchAPIDebounced();
    if (this.props.location.pathname !== "/") {
      this.props.history.push({
        pathname: "/",
        state: { calledFetchData: true }
      });
    }
  };

  openFilters = () => {
    this.setState({ openFilters: !this.state.openFilters });
  };

  render() {
    return (
      <div className={css(styles.flexed)}>
        {/* <div className={css(styles.filtersHolder)}>
          <button
            onClick={() => this.openFilters()}
            className={css(styles.searchButton)}
          >
            All
          </button>
          {this.state.openFilters && (
            <div className={css(styles.filters)}>
              <span
                onClick={() => this.openFilters()}
                className={css(styles.screenMessageClose)}
              >
                x
              </span>

              <div>
                <h4 className={css(styles.filterTitle)}>Filters:</h4>
                <span className={css(styles.filterButton)}>
                  Done: <span className={css(styles.selectedFilter)}>All</span>
                  <img
                    className={css(styles.chevron)}
                    src="/images/icons/downchevron.svg"
                    width="10px"
                    alt="Chevron"
                  />
                </span>
                <span className={css(styles.filterButton)}>
                  Comp: <span className={css(styles.selectedFilter)}>All</span>
                  <img
                    className={css(styles.chevron)}
                    src="/images/icons/downchevron.svg"
                    width="10px"
                    alt="Chevron"
                  />
                </span>
              </div>
            </div>
          )}
        </div> */}
        <input
          name="search"
          type="text"
          component="input"
          autoComplete="off"
          className={css(styles.formInput)}
          placeholder="Search e.g. English Premier ..."
          onChange={this.handleChange}
          value={this.state.searchText}
          maxLength={50}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  flexed: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  formInput: {
    padding: `${GUTTER}px`,
    margin: `${GUTTER * 2}px ${GUTTER * 2}px ${GUTTER * 2}px 0`,
    color: Color.WHITE,
    backgroundColor: Color.BLACK,
    border: "0px solid",
    width: "100%",
    borderRadius: `0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px 0`,
    backgroundImage: `url("/images/icons/magnifyingglass.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "20px"
  },
  searchButton: {
    backgroundColor: Color.BRICK_RED,
    borderRadius: ` ${BORDER_RADIUS}px 0 0 ${BORDER_RADIUS}px`,
    border: "none",
    color: Color.WHITE,
    lineHeight: "24px",
    width: "40px",
    cursor: "pointer"
  },
  filtersHolder: {
    position: "relative"
  },
  filters: {
    position: "absolute",
    top: "50px",
    left: "-60px",
    zIndex: "300",
    width: "calc(100vw - 20px)",
    borderRadius: `${BORDER_RADIUS}px`,
    fontFamily: PRIMARY_TEXT,
    fontSize: "12px",
    padding: `${GUTTER}px`,
    backgroundColor: Color.PRIMARY_GREEN,
    color: Color.WHITE,
    lineHeight: "20px",
    border: "1px solid rgba(255,255,255,0.4)",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    [`@media ${MEDIA_LARGE}`]: {
      left: `${GUTTER}px`,
      width: "85%"
    }
  },
  screenMessageClose: {
    position: "absolute",
    right: "-5px",
    top: "-5px",
    textAlign: "center",
    width: "24px",
    height: "24px",
    color: "white",
    fontSize: "14px",
    fontFamily: PRIMARY_TEXT,
    fontWeight: "bold",
    backgroundColor: Color.BRICK_RED,
    borderRadius: "100px",
    cursor: "pointer"
  },
  filterTitle: {
    margin: "0"
  },
  filterButton: {
    backgroundColor: `${Color.BLACK}`,
    borderRadius: `${BORDER_RADIUS}px`,
    textAlign: "center",
    fontSize: "11px",
    padding: "4px",
    marginRight: `${GUTTER}px`
  },
  selectedFilter: {
    marginRight: `${GUTTER / 2}px`
  },
  chevron: {
    position: "relative",
    top: "1px"
  }
});

function mapStateToProps(state) {
  return {
    searchText: state.search.searchText,
    offset: state.search.offset
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    { fetchMatches, changeSearchText }
  )(Search)
);
