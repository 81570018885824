import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";

import { GUTTER, BORDER_RADIUS } from "../../Global/Size";
import { Color } from "../../Global/Color";

import * as actions from "../../actions";
import { SEARCH_LIMIT } from "../../constants/match";
import { PRIMARY_TEXT } from "../../Global/Font";
class Paginator extends Component {
  back() {
    const { offset, searchText } = this.props;
    if (offset === 0) {
      return;
    }
    this.props.changeSearchOffset(offset - SEARCH_LIMIT)
    this.props.fetchMatches({
      searchText: searchText,
      offset: offset - SEARCH_LIMIT
    });
  }

  advance() {
    const { offset, totalCount, searchText } = this.props;
    if (offset + SEARCH_LIMIT > totalCount) {
      return;
    }
    this.props.changeSearchOffset(offset + SEARCH_LIMIT)
    this.props.fetchMatches({
      searchText,
      offset: offset + SEARCH_LIMIT
    });
  }

  left() {
    return (
      <button
        onClick={this.back.bind(this)}
        disabled={this.props.offset === 0}
        className={css([
          styles.button,
          this.props.offset === 0 && styles.disabled
        ])}
      >
        <img
          className={css(styles.img)}
          src="/images/icons/left-chevron.svg"
          width="10px"
          alt="Previous"
        />
      </button>
    );
  }

  right() {
    const { offset, totalCount } = this.props;

    const end = offset + SEARCH_LIMIT >= totalCount ? true : false;

    return (
      <button
        onClick={this.advance.bind(this)}
        disabled={end}
        className={css([styles.button, end && styles.disabled])}
      >
        <img
          className={css(styles.img)}
          src="/images/icons/right-chevron.svg"
          width="10px"
          alt="Next"
        />
      </button>
    );
  }

  render() {
    return (
      <div className={css(styles.holder)}>
        <div className={css(styles.pagination)}>
          {this.left()}
          <span>Page {this.props.offset / SEARCH_LIMIT + 1}</span>
          {this.right()}
        </div>
        <span>{this.props.totalCount} Matches Found</span>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  holder: {
    margin: "auto",
    textAlign: "center",
    fontFamily: PRIMARY_TEXT,
    color: Color.WHITE,
    fontSize: "11px"
  },
  pagination: {
    display: "inline-block"
  },
  button: {
    border: "none",
    backgroundColor: Color.BRICK_RED,
    padding: `${GUTTER}px`,
    cursor: "pointer",
    display: "inline-block",
    margin: `${GUTTER}px`,
    borderRadius: `${BORDER_RADIUS}px`
  },
  disabled: {
    opacity: "0.3"
  }
});

const mapStateToProps = state => {
  return {
    totalCount: state.matches.matches.data.totalCount,
    searchText: state.search.searchText,
    offset: state.search.offset
  };
};

export default connect(
  mapStateToProps,
  actions
)(Paginator);
