export const AUTH_USER = "AUTH_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTHENTICATING = "AUTHENTICATING";

export const GROWLER_HIDE = "GROWLER_HIDE";
export const GROWLER_HIDDEN = "GROWLER_HIDDEN";
export const GROWLER_SHOW = "GROWLER_SHOW";

export const FETCH_MATCHES = "FETCH_MATCHES";
export const FETCH_MATCHES_SUCCESS = "FETCH_MATCHES_SUCCESS";
export const FETCH_MATCHES_ERROR = "FETCH_MATCHES_ERROR";

export const SAVE_COMPLETED_MATCH_SUCCESS = "SAVED_COMPLETED_MATCH_SUCCESS";
export const CHANGE_SEARCH_TEXT = "CHANGE_SEARCH_TEXT";
export const CHANGE_SEARCH_OFFSET = "CHANGE_SEARCH_OFFSET";

export const ADMIN_FETCH_MATCHES = "ADMIN_FETCH_MATCHES";
export const ADMIN_FETCH_MATCHES_SUCCESS = "ADMIN_FETCH_MATCHES_SUCCESS";
export const ADMIN_FETCH_MATCHES_FAILURE = "ADMIN_FETCH_MATCHES_ERROR";

export const ACCEPT_COOKIE = "ACCEPT_COOKIE";
export const ACCEPT_ONBOARDING = "ACCEPT_ONBOARDING";

export const MAKING_PAYMENT = "MAKING_PAYMENT";
export const MAKING_PAYMENT_SUCCESS = "MAKING_PAYMENT_SUCCESS";
export const MAKING_PAYMENT_FAILURE = "MAKING_PAYMENT_FAILURE";
