export const COMPETITION_TO_BE_SET = "(Competition to be set)";
export const TEAM_NAME = "Team Name";
export const SEARCH_LIMIT = 20;

export const INITIAL_MATCH_TEMPLATE = {
  home: {
    name: TEAM_NAME,
    score: "0",
    badge: "",
    _id: null
  },
  away: {
    name: TEAM_NAME,
    score: "0",
    badge: "",
    _id: null
  },
  date: "(Date to be set)",
  competition: COMPETITION_TO_BE_SET,
  homeOrAway: "home",
  formation: "4-4-2",
  players: [
    {
      name: "",
      num: "1",
      y: "35",
      x: "80"
    },
    {
      name: "",
      num: "2",
      y: "135",
      x: "-22"
    },
    {
      name: "",
      num: "3",
      y: "85",
      x: "15"
    },
    {
      name: "",
      num: "4",
      y: "85",
      x: "145"
    },
    {
      name: "",
      num: "5",
      y: "135",
      x: "180"
    },
    {
      name: "",
      num: "6",
      y: "255",
      x: "-22"
    },
    {
      name: "",
      num: "7",
      y: "205",
      x: "15"
    },
    {
      name: "",
      num: "8",
      y: "205",
      x: "145"
    },
    {
      name: "",
      num: "9",
      y: "255",
      x: "180"
    },
    {
      name: "",
      num: "10",
      y: "315",
      x: "15"
    },
    {
      name: "",
      num: "11",
      y: "315",
      x: "155"
    }
  ],
  comment: {
    text: "",
    person: ""
  },
  shirt: "",
  goalieShirt: "",
  published: false,
  promoted: false
};
