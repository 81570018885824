import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { PRIMARY_TEXT } from "../../Global/Font";
import { GUTTER, BORDER_RADIUS } from "../../Global/Size";

import StripeCheckout from "react-stripe-checkout";

import { makePayment } from "../../actions/payment";
class StripeButton extends Component {
  onToken = token => {
    const { makePayment, price, auth, coins } = this.props;

    makePayment({
      auth,
      price,
      token,
      coins
    });
  };

  render() {
    const priceForStripe = this.props.price * 100;
    const publishableKey = "pk_test_nyQSWWCkdMST7JxpXdkH9VFA00d2UEIzah";
    return (
      <StripeCheckout
        label="Pay now"
        name="That Football Quiz"
        description={`You will be paying £${this.props.price.toFixed(2)}`}
        amount={priceForStripe}
        panelLabel="Pay Now"
        token={data => this.onToken(data)}
        stripeKey={publishableKey}
        currency={"GBP"}
      >
        <button className={css(styles.btn)}>{this.props.text}</button>
      </StripeCheckout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.authenticated,
    user: state.auth.user
  };
};

export default connect(
  mapStateToProps,
  { makePayment }
)(StripeButton);

const styles = StyleSheet.create({
  btn: {
    fontFamily: PRIMARY_TEXT,
    fontSize: "16px",
    width: "100%",
    border: "none",
    margin: `${GUTTER}px 0`,
    padding: `${GUTTER}px`,
    borderRadius: `${BORDER_RADIUS}px`
  }
});
