import {
  AUTH_USER,
  AUTH_ERROR,
  SAVE_COMPLETED_MATCH_SUCCESS,
  AUTHENTICATING
} from "../actions/types";

const INITIAL_STATE = {
  authenticated: "",
  errorMessage: "",
  authenticating: false,
  user: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTHENTICATING: 
      return {
        ...state,
        authenticating: true
      }
    case AUTH_USER:
      return {
        ...state,
        authenticated: action.payload.token,
        user: action.payload.user,
        authenticating: false
        // userId: action.payload.userId,
        // completed: action.payload.completed || null
      };
    case AUTH_ERROR:
      return { ...state, errorMessage: action.payload, authenticating: false };
    case SAVE_COMPLETED_MATCH_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          completed: action.payload.completed
        },
        authenticating: false
      };
    default:
      if (state.errorMessage !== "") {
        return {
          ...state,
          errorMessage: "",
          authenticating: false
        };
      }
      return state;
  }
}
