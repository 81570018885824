import axios from "axios";

export const sendFeedback = (formProps, auth, callback) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;
  const headers = { Authorization: auth.authenticated };

  const response = await axios.post(
    `${baseURI}/feedback`,
    {
      ...formProps,
      userId: auth.user._id
    },
    { headers }
  );

  callback(response);
};
