import { ACCEPT_ONBOARDING } from "../actions/types";

const INITIAL_STATE = {
  acceptedOnboarding: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACCEPT_ONBOARDING:
      return {
        acceptedOnboarding: [...state.acceptedOnboarding, action.payload]
      };
    default:
      return state;
  }
}
