import React from "react";
import { StyleSheet, css } from "aphrodite";
import { GUTTER, BORDER_RADIUS } from "../../Global/Size";
import { PRIMARY_TEXT } from "../../Global/Font";
import { Color } from "../../Global/Color";

export default ({ coins, showShopper }) => (
  <div className={css(styles.flexed)}>
    <button className={css(styles.button)} onClick={() => showShopper(true)}>
      <span className={css(styles.tokenAmount)}>{coins ? coins : 0}</span>
    </button>
  </div>
);

const styles = StyleSheet.create({
  flexed: {
    display: "flex",
    alignItems: "center"
  },
  button: {
    height: "2em",
    borderRadius: `${BORDER_RADIUS}px`,
    backgroundColor: `${Color.PRIMARY_GREEN}`,
    marginRight: `${GUTTER}px`,
    fontFamily: PRIMARY_TEXT,
    fontSize: "14px",
    color: Color.WHITE,
    border: `2px solid ${Color.SECONDARY_GREEN}`,
    width: "60px",
    backgroundImage: "url('/images/icons/coin.png')",
    backgroundSize: "20px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "4px"
  },
  coin: {},
  tokenAmount: {
    display: "inline-block",
    position: "relative",
    padding: "0 8px",
    left: "10px",
    textAlign: "center",
    color: Color.WHITE,
    backgroundColor: "yellowgreen",
    borderRadius: `${GUTTER}px`
  }
});
