import React from "react";
import { StyleSheet, css } from "aphrodite";

import { GUTTER } from "../../Global/Size";

const Body = ({ children }) => {
  return <div className={css(styles.container)}>{children}</div>;
};

const styles = StyleSheet.create({
  container: {
    margin: `${GUTTER * 2} 0`
  }
});

export default Body;
