import { ACCEPT_COOKIE, ACCEPT_ONBOARDING } from "../actions/types";

const localStorageManager = () => next => action => {
  if (action.type === ACCEPT_COOKIE) {
    if (localStorage.getItem("acceptedCookies") !== action.payload) {
      localStorage.setItem("acceptedCookies", action.payload);
    }
  }
  if (action.type === ACCEPT_ONBOARDING) {
    const onboarded = localStorage.getItem("onboarded");
    if (onboarded) {
      const newOnboarded = JSON.parse(onboarded);
      newOnboarded.concat(action.payload);
      localStorage.setItem("onboarded", JSON.stringify(newOnboarded));
    } else {
      localStorage.setItem("onboarded", JSON.stringify([action.payload]));
    }
  }
  return next(action);
};

export default localStorageManager;
