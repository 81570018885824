import React from "react";

import { StyleSheet, css } from "aphrodite";
import { Color } from "../../Global/Color";
import { GUTTER, BORDER_RADIUS } from "../../Global/Size";

import { PRIMARY_TEXT } from "../../Global/Font";

export default props => (
  <div
    className={css(styles.cookieBanner)}
    style={props.extraStyles && props.extraStyles}
  >
    {props.children}
    <button onClick={() => props.onClick()} className={css(styles.button)}>
      {props.buttonText}
    </button>
  </div>
);

const styles = StyleSheet.create({
  cookieBanner: {
    position: "absolute",
    width: "90%",
    maxWidth: "300px",
    textAlign: "center",
    backgroundColor: Color.PRIMARY_BLUE,
    padding: `${GUTTER * 2}px`,
    color: Color.WHITE,
    fontFamily: PRIMARY_TEXT,
    zIndex: 10000,
    lineHeight: "1.5em",
    borderRadius: `${BORDER_RADIUS * 2}px`
  },
  button: {
    margin: `${GUTTER * 2}px auto`,
    padding: `${GUTTER}px ${GUTTER * 2}px`,
    backgroundColor: Color.PRIMARY_BLUE,
    border: `1px solid ${Color.WHITE}`,
    borderRadius: `${BORDER_RADIUS}px`,
    color: Color.WHITE,
    cursor: "pointer"
  }
});
