import React from "react";
import { StyleSheet, css } from "aphrodite";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

export default ({ shareUrl, shareTitle, size }) => (
  <div className={css(styles.flexed)}>
    <FacebookShareButton url={shareUrl} quote={shareTitle}>
      <FacebookIcon size={size} round />
    </FacebookShareButton>
    <TwitterShareButton url={shareUrl} title={shareTitle}>
      <TwitterIcon size={size} round />
    </TwitterShareButton>
    <WhatsappShareButton url={shareUrl} title={shareTitle} separator=":: ">
      <WhatsappIcon size={size} round />
    </WhatsappShareButton>
    <EmailShareButton url={shareUrl} subject={shareTitle} body="body">
      <EmailIcon size={size} round />
    </EmailShareButton>
  </div>
);

const styles = StyleSheet.create({
  flexed: {
    display: "flex",
    justifyContent: "space-between"
  }
});
