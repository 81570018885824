import {
  FETCH_MATCHES,
  FETCH_MATCHES_SUCCESS,
  FETCH_MATCHES_ERROR
} from "../actions/types";

const INITIAL_STATE = {
  loading: true,
  matches: null,
  errorMessage: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_MATCHES:
      return {
        loading: true,
        matches: null,
        errorMessage: null
      };
    case FETCH_MATCHES_SUCCESS:
      return {
        matches: action.payload,
        loading: false,
        errorMessage: null
      };
    case FETCH_MATCHES_ERROR:
      return {
        matches: null,
        loading: false,
        errorMessage: action.payload
      };
    default:
      return state;
  }
}
