import React from "react";
import { StyleSheet, css } from "aphrodite";

import { BORDER_RADIUS, GUTTER } from "../../Global/Size";
import { Color } from "../../Global/Color";

import { createDate } from "../../utils";
import { config } from "../../config";

const getCheckedItem = checked =>
  checked ? (
    <img src="/images/icons/checked.svg" width="10px" alt="Ticked" />
  ) : (
    <img src="/images/icons/cancel.svg" width="10px" alt="Crossed" />
  );

export default ({
  home,
  away,
  competition,
  date,
  highlight,
  homeOrAway,
  newMatch,
  completed
}) => {
  return (
    <div className={css([styles.container, highlight && styles.highlight])}>
      {newMatch && <span className={css(styles.newMatch)}>NEW</span>}
      <div className={css(styles.scores)}>
        {home.badge ? (
          <span className={css(styles.badgeBackground)}>
            <img
              src={`${config.prod.imgUrl}${home.badge}`}
              alt={home.name}
              height="40px"
              width="40px"
            />
          </span>
        ) : (
          <span className={css(styles.badgeBackground)}></span>
        )}
        <span className={css(styles.matchScore)}>{home.score}</span>
        <span className={css(styles.v)}>v</span>
        <span className={css(styles.matchScore)}>{away.score}</span>
        {away.badge ? (
          <span className={css(styles.badgeBackground)}>
            <img
              src={`${config.prod.imgUrl}${away.badge}`}
              alt={away.name}
              height="40px"
              width="40px"
            />
          </span>
        ) : (
          <span className={css(styles.badgeBackground)}></span>
        )}
      </div>
      <h3 className={css(styles.title)}>
        <div className={css([styles.teamName, styles.homeName])}>
          <span
            className={css([
              styles.name,
              homeOrAway === "home" && styles.selectedName
            ])}
          >
            {home.name}
          </span>
        </div>
        <div className={css(styles.space)}>vs</div>
        <div className={css([styles.teamName, styles.awayName])}>
          <span
            className={css([
              styles.name,
              homeOrAway === "away" && styles.selectedName
            ])}
          >
            {away.name}
          </span>
        </div>
      </h3>
      <div className={css(styles.meta)}>
        <div>{competition}</div>
        <div>{createDate(date)}</div>
        {completed && (
          <span className={css(styles.completed)}>
            Done {getCheckedItem(completed)}
          </span>
        )}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    textAlign: "center",
    color: Color.WHITE,
    cursor: "pointer"
  },
  highlight: {},
  title: {
    display: "flex",
    fontFamily: "Arial, sans-serif",
    fontWeight: "normal",
    fontSize: "12px",
    textAlign: "center",
    whiteSpace: "nowrap",
    overFlow: "hidden",
    textOverflow: "ellipsis"
  },
  scores: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "300px",
    margin: "auto"
  },
  matchScore: {
    display: "inline-block",
    backgroundColor: Color.BLACK,
    height: "40px",
    fontSize: "20px",
    position: "relative",
    top: "7px",
    padding: "8px 14px 0px 14px",
    fontWeight: "bold",
    margin: "0 7px",
    borderRadius: `${BORDER_RADIUS}px`
  },
  v: {
    lineHeight: "50px",
    fontSize: "20px"
  },
  space: {
    flex: "0 0 10%",
    lineHeight: "20px"
  },
  selectedName: {
    backgroundColor: Color.BRICK_RED
  },
  teamName: {
    flex: "0 0 45%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  name: {
    padding: "4px",
    lineHeight: "20px"
  },
  awayName: {
    textAlign: "left"
  },
  homeName: {
    textAlign: "right"
  },
  meta: {
    fontSize: "12px",
    lineHeight: "20px"
  },
  newMatch: {
    fontSize: "10px",
    padding: `${GUTTER / 2}px`,
    backgroundColor: Color.BRICK_RED,
    borderRadius: `${BORDER_RADIUS / 2}px`,
    position: "absolute",
    top: "-20px",
    right: "-20px"
  },
  badgeBackground: {
    height: "50px",
    width: "50px",
    backgroundColor: "rgba(255,255,255,0.3)",
    paddingTop: "5px",
    borderRadius: "40px"
  },
  completed: {
    position: "absolute",
    right: "0",
    bottom: "-5px",
    padding: "3px",
    backgroundColor: `${Color.BLACK}`,
    borderRadius: `${BORDER_RADIUS}px`,
    textAlign: "center",
    fontSize: "11px",
    width: "50px"
  }
});
