import axios from "axios";

import { AUTH_USER, AUTH_ERROR, AUTHENTICATING } from "./types";

export const signup = (formProps, callback) => async (dispatch, getState) => {
  const { baseURI } = getState().appConfig;
  try {
    const response = await axios.post(`${baseURI}/signup`, { formProps });
    callback(response);
  } catch (err) {
    if (err.response) {
      return dispatch({ type: AUTH_ERROR, payload: err.response.data.message });
    }
    return dispatch({
      type: AUTH_ERROR,
      payload: "Something has gone wrong. Please try again."
    });
  }
};

export const signin = (formProps, callback) => async (dispatch, getState) => {
  dispatch({
    type: AUTHENTICATING
  });

  const { baseURI } = getState().appConfig;
  try {
    const response = await axios.post(`${baseURI}/signin`, {
      ...formProps
    });

    const expires = new Date().getTime() + 12 * 60 * 60 * 1000;
    const user = {
      ...response.data.user,
      expires
    };

    dispatch({
      type: AUTH_USER,
      payload: {
        token: response.data.token,
        user
      }
    });

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user", JSON.stringify(user));
    callback();
  } catch (err) {
    const message = "Invalid log in credentials";
    dispatch({ type: AUTH_ERROR, payload: message });
  }
};

export const verifyEmail = (formProps, callback) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;
  try {
    await axios.post(`${baseURI}/verifyEmail`, {
      ...formProps
    });
    callback();
  } catch (err) {
    const message = err.response.data.message || "Invalid details";
    dispatch({ type: AUTH_ERROR, payload: message });
  }
};

export const resendVerificationEmail = (formProps, callback) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;
  try {
    await axios.post(`${baseURI}/resendVerificationEmail`, {
      ...formProps
    });
    callback();
  } catch (err) {
    let message =
      "Sorry, we seem to be having a problem at the minute. You may need to request a new email.";
    if (err.response) {
      message = err.response.data.message || "Invalid details";
    }
    dispatch({ type: AUTH_ERROR, payload: message });
  }
};

export const signout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  return {
    type: AUTH_USER,
    payload: ""
  };
};

export const forgottenPasswordEmail = (formProps, callback) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;
  try {
    const response = await axios.post(`${baseURI}/forgottenPasswordEmail`, {
      ...formProps
    });
    callback(response);
  } catch (err) {
    let message =
      "Sorry, we seem to be having a problem at the minute. Please try again later.";
    if (err.response) {
      message = err.response.data.message || "Invalid details";
    }
    dispatch({ type: AUTH_ERROR, payload: message });
  }
};

export const resetPassword = (formProps, callback) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;
  try {
    const response = await axios.post(`${baseURI}/resetPassword`, {
      ...formProps
    });
    callback(response);
  } catch (err) {
    let message =
      "Sorry, we seem to be having a problem at the minute. You may need to request a new reset password email.";
    if (err.response) {
      message = err.response.data.message || "Invalid details";
    }
    dispatch({ type: AUTH_ERROR, payload: message });
  }
};
