
import React from "react";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export const createDate = date => {
  const dateObject = new Date(Date.parse(date));
  return isValidDate(dateObject) ? dateObject.toDateString() : date;
};

export const getCheckedItem = checked =>
checked ? (
  <img src="/images/icons/checked.svg" width="10px" alt="Ticked" />
) : (
  <img src="/images/icons/cancel.svg" width="10px" alt="Crossed" />
);
