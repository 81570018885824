import React, { useState, useEffect } from "react";
import { StyleSheet, css } from "aphrodite";

import { GUTTER, BORDER_RADIUS } from "../../Global/Size";
import { PRIMARY_TEXT } from "../../Global/Font";
import { Color } from "../../Global/Color";

const Timer = ({isActive}) => {

  const prefixZero = num => {
    return num < 10 ? `0${num}`: num;
  }
  const createTimer = seconds => {
    const mins = Math.floor(seconds/60);
    const secs = seconds%60;
    return `${prefixZero(mins)}:${prefixZero(secs)}`;
  }
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  return <div className={css(styles.container)}>{createTimer(seconds)}</div>;
};

export default Timer;

const styles = StyleSheet.create({
  container: {
    display: "flex",
    backgroundColor: "#222222",
    padding: `${GUTTER}px`,
    color: Color.WHITE,
    borderRadius: `${BORDER_RADIUS}px`,
    fontFamily: PRIMARY_TEXT,
    fontSize: "20px",
    letterSpacing: "0.1em",
    textAlign: "center"
  }
});
