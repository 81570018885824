export const config = {
  dev: {
    baseURI: "http://localhost:5000/api",
    appURI: "https://localhost:3000",
    imgUrl:
      "https://res.cloudinary.com/ds2vdvmju/image/upload/t_120/v1567192682/tfq/"
  },
  prod: {
    baseURI: process.env.REACT_APP_SERVER_URI
      ? `${process.env.REACT_APP_SERVER_URI}/api`
      : "https://thatfootballquiz.com/api",
    appURI: process.env.REACT_APP_SERVER_URI
      ? "https://thatfootballquiz.herokuapp.com"
      : "https://thatfootballquiz.com",
    imgUrl:
      "https://res.cloudinary.com/ds2vdvmju/image/upload/t_120/v1567192682/tfq/"
  }
};
