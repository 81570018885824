import React from "react";
import { StyleSheet, css } from "aphrodite";

import { Color } from "../../Global/Color";

export default ({ size }) => {
  const newSize = size || 50;
  const divStyle = {
    width: `${newSize}px`,
    height: `${newSize}px`
  };
  return (
    <div className={css(styles.ring)} style={divStyle}>
      <div className={css(styles.ringCircle)}></div>
      <div className={css(styles.ringCircle)}></div>
      <div className={css(styles.ringCircle)}></div>
      <div className={css(styles.ringCircle)}></div>
    </div>
  );
};

const spin = {
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(360deg)"
  }
};

const styles = StyleSheet.create({
  ring: {
    display: "inline-block",
    position: "relative"
  },
  ringCircle: {
    boxSizing: "border-box",
    display: "block",
    position: "absolute",
    width: "100%",
    height: "100%",
    border: `6px solid ${Color.PRIMARY_GREEN}`,
    borderRadius: "50%",
    animationName: spin,
    animationDuration: "1.2s",
    animationTimingFunction: "cubic-bezier(0.5, 0, 0.5, 1)",
    animationIterationCount: "infinite",
    borderColor: `${Color.PRIMARY_GREEN} transparent transparent transparent`,
    ":nth-child(1)": {
      animationDelay: "-0.45s"
    },
    ":nth-child(2)": {
      animationDelay: "-0.3s"
    },
    ":nth-child(3)": {
      animationDelay: "-0.15s"
    }
  }
});
