import axios from "axios";
import { SAVE_COMPLETED_MATCH_SUCCESS } from "./types";

export const saveCompletedMatch = ({ completed, userId }, callback) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;

  try {
    const response = await axios.post(`${baseURI}/completedMatches`, {
      completed,
      userId
    });
    const user = JSON.parse(localStorage.getItem("user"));
    user.completed = completed;
    localStorage.setItem("user", JSON.stringify(user));
    dispatch({
      type: SAVE_COMPLETED_MATCH_SUCCESS,
      payload: { completed, message: response.data.message }
    });
    callback(null, response);
  } catch (err) {
    callback(err);
  }
};
