import React from "react";
import { StyleSheet, css } from "aphrodite";

import { GUTTER, BORDER_RADIUS } from "../../Global/Size";
import { PRIMARY_TEXT } from "../../Global/Font";

export default function Card({ children, highlight }) {
  return (
    <div className={css([styles.container, highlight && styles.highlight])}>
      {children}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: `${GUTTER}px`,
    padding: `${GUTTER * 2}px`,
    borderRadius: `${BORDER_RADIUS}px`,
    backgroundColor: "rgba(0,0,0,0.4)",
    fontFamily: PRIMARY_TEXT,
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
  },
  highlight: {
    gridRow: "1 / 3"
  }
});
