import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import growl from "./growl";
import appConfig from "./appConfig";
import ffs from "./ffs";
import matches from "./matches";
import search from "./search";
import cookieManager from "./cookieManager";
import onboarding from "./onboarding";
import payment from "./payment";

export default combineReducers({
  auth,
  form: formReducer,
  matches,
  appConfig,
  ffs,
  growl,
  search,
  cookieManager,
  onboarding,
  payment
});
