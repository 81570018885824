import React from "react";
import { StyleSheet, css } from "aphrodite";
import { Color } from "../../Global/Color";

const Switch = ({ checked = false, onChange, label }) => {
  return (
    <>
      <input
        checked={checked}
        onChange={onChange}
        className={css(styles.checkbox)}
        id={label}
        type="checkbox"
      />
      <label className={css(styles.label)} htmlFor={label}>
        <span
          className={css([
            styles.button,
            styles.labelButton,
            checked && styles.isChecked
          ])}
        />
      </label>
    </>
  );
};

export default Switch;

const styles = StyleSheet.create({
  checkbox: {
    height: "0",
    width: "0",
    visibility: "hidden"
  },
  label: {
    display: "inline-block",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "50px",
    maxWidth: "50px",
    height: "15px",
    background: "grey",
    borderRadius: "100px",
    position: "relative",
    transition: "background-color .2s"
  },
  labelButton: {
    content: "",
    position: "absolute",
    top: "-2px",
    left: "2px",
    width: "20px",
    height: "20px",
    borderRadius: "40px",
    transition: "0.2s",
    background: Color.WHITE,
    boxShadow: "0 0 2px 0 rgba(10, 10, 10, 0.29)"
  },
  isChecked: {
    left: "calc(100% - 2px)",
    transform: "translateX(-100%)",
    backgroundColor: "yellow"
  }

  // .react-switch-label:active .react-switch-button {
  //   width: 60px;
  // }
});
