import {
  signin,
  signout,
  signup,
  verifyEmail,
  resendVerificationEmail,
  forgottenPasswordEmail,
  resetPassword
} from "./auth";

import { saveCompletedMatch } from "./userMeta";
import { showGrowler, hideGrowler } from "./growl";
import { fetchMatches } from "./matches";
import { sendFeedback } from "./feedback";

import { changeSearchText, changeSearchOffset } from "./search";
import { acceptCookies } from "./cookieManager";
import { acceptOnboarding } from "./onboarding";

export {
  signin,
  signout,
  signup,
  verifyEmail,
  resendVerificationEmail,
  forgottenPasswordEmail,
  resetPassword,
  saveCompletedMatch,
  showGrowler,
  hideGrowler,
  fetchMatches,
  changeSearchText,
  changeSearchOffset,
  sendFeedback,
  acceptCookies,
  acceptOnboarding
};
