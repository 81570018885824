import { CHANGE_SEARCH_TEXT, CHANGE_SEARCH_OFFSET } from "../actions/types";

const INITIAL_STATE = {
  searchText: "",
  offset: 0
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload
      };
      case CHANGE_SEARCH_OFFSET:
          return {
            ...state,
            offset: action.payload
          };
    default:
      return state;
  }
}
