import React from "react";
import { StyleSheet, css } from "aphrodite";

import { PRIMARY_TEXT } from "../../Global/Font";
import Card from "./Card";
import Loader from "../Icons/Loader";
import { Color } from "../../Global/Color";

export default () => (
  <Card>
    <div className={css(styles.container)}>
      <Loader />
      <div>Loading</div>
    </div>
  </Card>
);

const styles = StyleSheet.create({
  container: {
    fontFamily: PRIMARY_TEXT,
    color: Color.PRIMARY_GREEN,
    textAlign: "center"
  }
});
