import React from "react";
import { StyleSheet, css } from "aphrodite";

import SocialSharer from "../PageElements/SocialSharer";
import { Color } from "../../Global/Color";
import { PRIMARY_TEXT } from "../../Global/Font";
import { GUTTER } from "../../Global/Size";
import { config } from "../../config";

const Footer = () => {
  const appConfig =
    process.env.REACT_APP_SERVER_URI || process.env.NODE_ENV === "production"
      ? config.prod
      : config.dev;
  return (
    <div className={css(styles.footer)}>
      <div className={css(styles.socialShare)}>
        <SocialSharer
          shareTitle={"Play That Football Quiz"}
          shareUrl={appConfig.appURI}
          size={24}
        />
      </div>
    </div>
  );
};

export default Footer;

const styles = StyleSheet.create({
  footer: {
    right: "0",
    bottom: "0",
    left: "0",
    backgroundColor: `${Color.SECONDARY_GREEN}`,
    textAlign: "center",
    color: Color.WHITE,
    fontFamily: PRIMARY_TEXT,
    padding: `${GUTTER}px`,
    display: "flex"
  },
  socialShare: {
    width: "150px"
  }
});
