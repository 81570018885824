import React from "react";
import { StyleSheet, css } from "aphrodite";

import { MEDIA_MEDIUM, MEDIA_EXTRA_LARGE, GUTTER } from "../../Global/Size";

export default function Grid({ children }) {
  return <div className={css(styles.container)}>{children}</div>;
}

const styles = StyleSheet.create({
  container: {
    display: "grid",
    gridTemplateColumns: "5fr",
    gridColumnGap: `${GUTTER}px`,
    [`@media ${MEDIA_MEDIUM}`]: {
      gridTemplateColumns: "2fr 2fr"
    },
    [`@media ${MEDIA_EXTRA_LARGE}`]: {
      gridColumnGap: `${GUTTER / 2}px`,
      gridTemplateColumns: "1fr 1fr 1fr 1fr"
    }
  }
});
