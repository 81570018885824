import React from "react";
import { Route, Redirect } from "react-router-dom";

export const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => {
    return (
      rest.user && rest.user.role === "admin"
        ? <Component {...props} />
        : <Redirect to='/' />
    )}} />
  )}
