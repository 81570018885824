import { CHANGE_SEARCH_TEXT, CHANGE_SEARCH_OFFSET } from "./types";

export const changeSearchText = payload => ({
  type: CHANGE_SEARCH_TEXT,
  payload
});

export const changeSearchOffset = payload => ({
  type: CHANGE_SEARCH_OFFSET,
  payload
});
