import React, { Component } from "react";
import { connect } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { NavLink } from "react-router-dom";

import { Color } from "../../Global/Color";
import { PRIMARY_TEXT } from "../../Global/Font";
import { GUTTER, BORDER_RADIUS } from "../../Global/Size";

class Nav extends Component {
  renderLinks() {
    if (this.props.authenticated) {
      return (
        <div className={css([styles.gridRestrictor, styles.navList])}>
          <div className={css(styles.navChild)}>
            <NavLink to="/" className={css(styles.navItem)}>
              Home
            </NavLink>
            <NavLink to="/howtoplay" className={css(styles.navItem)}>
              How To Play
            </NavLink>
            <NavLink to="/createMatch" className={css(styles.navItem)}>
              Create Match
            </NavLink>
            <NavLink to="/userMatches" className={css(styles.navItem)}>
              My Matches
            </NavLink>
            {this.props.user &&
              this.props.user.role &&
              this.props.user.role === "admin" && (
                <>
                  <NavLink to="/admin" className={css(styles.navItem)}>
                    Admin
                  </NavLink>
                  <NavLink
                    to="/featureswitches"
                    className={css(styles.navItem)}
                  >
                    Feature Switches
                  </NavLink>
                </>
              )}
          </div>
          <div className={css(styles.navChild)}>
            <NavLink to="/feedback" className={css(styles.navItem)}>
              Request/Feedback
            </NavLink>
            <NavLink to="/signout" className={css(styles.navItem)}>
              Sign Out
            </NavLink>
          </div>
        </div>
      );
    }
    return (
      <div className={css([styles.gridRestrictor, styles.navList])}>
        <div className={css(styles.navChild)}>
          <NavLink to="/" className={css(styles.navItem)}>
            Home
          </NavLink>
          <NavLink to="/howtoplay" className={css(styles.navItem)}>
            How To Play
          </NavLink>
        </div>
        <div className={css(styles.navChild)}>
          <NavLink to="/signup" className={css(styles.navItem)}>
            Sign Up
          </NavLink>
          <NavLink to="/signin" className={css(styles.navItem)}>
            Sign In
          </NavLink>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={css(styles.navHolder)}>
        <nav>{this.renderLinks()}</nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(Nav);

const styles = StyleSheet.create({
  gridRestrictor: {
    maxWidth: "1024px",
    margin: `auto`
  },
  navHolder: {
    boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.15)",
    borderTop: `1px solid ${Color.PRIMARY_GREEN}`,
    backgroundColor: Color.SECONDARY_GREEN,
    overflow: "hidden",
    height: "43px"
  },
  navList: {
    display: "flex",
    justifyContent: "space-between",
    overflow: "scrollX",
    overflowX: "scroll",
    position: "relative"
  },
  navChild: {
    display: "flex"
  },
  navItem: {
    padding: "6px 6px",
    maxWidth: "264px",
    minWidth: "72px",
    whiteSpace: "nowrap",
    textAlign: "center",
    margin: `${GUTTER}px`,
    color: Color.WHITE,
    fontFamily: PRIMARY_TEXT,
    fontSize: "12px",
    textDecoration: "none",
    backgroundColor: `${Color.PRIMARY_GREEN}`,
    borderRadius: `${BORDER_RADIUS}px`,
    alignSelf: "center",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)"
  }
});
