import axios from "axios";

import { SEARCH_LIMIT } from "../constants/match";

import {
  FETCH_MATCHES,
  FETCH_MATCHES_SUCCESS,
  FETCH_MATCHES_ERROR
} from "./types";

export const fetchMatches = ({ searchText, offset }) => async (
  dispatch,
  getState
) => {
  const { baseURI } = getState().appConfig;

  dispatch({
    type: FETCH_MATCHES
  });
  try {
    const response = await axios.get(`${baseURI}/matches`, {
      params: {
        searchText,
        offset,
        limit: SEARCH_LIMIT
      }
    });

    return dispatch({
      type: FETCH_MATCHES_SUCCESS,
      payload: {
        data: response.data,
        err: null
      }
    });
  } catch (err) {
    // if (err.response) {
    //   return dispatch({
    //     type: FETCH_MATCHES_ERROR,
    //     payload: err.response.data.message
    //   });
    // }
    return dispatch({
      type: FETCH_MATCHES_ERROR,
      payload: "Something went wrong. Please try again later."
    });
  }
};
