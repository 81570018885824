import React from "react";
import { StyleSheet, css } from "aphrodite";
import { Color } from "../../Global/Color";
import Card from "./Card";

export default ({ message }) => (
  <div className={css(styles.container)}>
    <Card>{message}</Card>
  </div>
);

const styles = StyleSheet.create({
  container: {
    minWidth: "250px",
    maxWidth: "500px",
    margin: "auto",
    color: Color.WHITE,
    lineHeight: "1.85em",
    textAlign: "center"
  }
});
