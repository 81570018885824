import { GROWLER_HIDE, GROWLER_SHOW } from "./types";

let hideTimeout;

export const showGrowler = payload => ({
  type: GROWLER_SHOW,
  payload
});

export const hideGrowler = time => {
  const timeoutTime = time || 3000;
  return dispatch => {
    clearTimeout(hideTimeout);
    hideTimeout = window.setTimeout(() => {
      dispatch({
        type: GROWLER_HIDE
      });
    }, timeoutTime);
  };
};
